@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital@0;1&family=IBM+Plex+Sans+Condensed:ital@0;1&family=IBM+Plex+Sans:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&family=IBM+Plex+Serif:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("react-datepicker/dist/react-datepicker.css");
@import "rsuite/dist/rsuite.css";

body {
  margin: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiListSubheader-root {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.8rem !important;
  box-sizing: border-box;
  list-style: none;
  font-weight: 700 !important;
  line-height: 40px !important;
}

.breadcrumb {
  list-style: none;
  overflow: hidden;
  font: 18px Sans-Serif;
}
.breadcrumb li {
  float: left;
}
.breadcrumb li a {
  color: white;
  text-decoration: none;
  padding: 10px 0 10px 65px;
  background: brown; /* fallback color */
  background: hsla(34, 85%, 35%, 1);
  position: relative;
  display: block;
  float: left;
}

.breadcrumb li a::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid hsla(34, 85%, 35%, 1);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb li a::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumb li:first-child a {
  padding-left: 10px;
}
.breadcrumb li:nth-child(2) a {
  background: hsla(34, 85%, 45%, 1);
}
.breadcrumb li:nth-child(2) a:after {
  border-left-color: hsla(34, 85%, 45%, 1);
}
.breadcrumb li:nth-child(3) a {
  background: hsla(34, 85%, 55%, 1);
}
.breadcrumb li:nth-child(3) a:after {
  border-left-color: hsla(34, 85%, 55%, 1);
}
.breadcrumb li:nth-child(4) a {
  background: hsla(34, 85%, 65%, 1);
}
.breadcrumb li:nth-child(4) a:after {
  border-left-color: hsla(34, 85%, 65%, 1);
}
.breadcrumb li:nth-child(5) a {
  background: hsla(34, 85%, 75%, 1);
}
.breadcrumb li:nth-child(5) a:after {
  border-left-color: hsla(34, 85%, 75%, 1);
}
.breadcrumb li:last-child a {
  background: transparent !important;
  color: black;
  pointer-events: none;
  cursor: default;
}
.breadcrumb li:last-child a::after {
  border: 0;
}

.breadcrumb li a:hover {
  background: hsla(34, 85%, 25%, 1);
}
.breadcrumb li a:hover:after {
  border-left-color: hsla(34, 85%, 25%, 1) !important;
}

.show-print {
  display: none !important;
}
.wqms-kpi {
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255, 0.2);
  box-shadow: 0 0 1px transparent;
}

.filler-status-graph {
  min-height: 35vh;
  border: 1px solid rgb(255, 255, 255, 0.2);
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 5px;
}

@media print {
  #graphs {
    position: absolute !important;
    left: 0px;
    top: 500px;
  }
  .hide-print {
    display: none !important;
  }
  .show-print {
    left: 0px;
    top: -40px;
    position: absolute;
    display: inline-block !important;
    margin: 10mm 10mm 10mm 4.2mm;
    padding: 0mm 0mm 6mm 0mm;
    background-color: rgb(35, 42, 88) !important;
  }
  .show-print-sat {
    left: 0px;
    top: -40px;
    position: absolute;
    display: inline-block !important;
    margin: 10mm 10mm 10mm 4.2mm;
    padding: 0mm 0mm 6mm 0mm;
    background-color: rgb(35, 42, 88) !important;
  }
  .pagebreak {
    page-break-before: always;
  }
  .react-grid-item {
    position: unset !important;
    transform: unset !important;
    margin: 10px 2px 10px 2px;
    display: inline-block;
  }
}

@page {
  size: 300mm 420mm;
  margin-left: 1mm;
  margin-right: 1mm;
  /* margin-top: 13mm;
  margin-bottom: 87mm; */
  font-size: 8px;
}
@page :footer {
  display: none !important;
}

@page :header {
  display: none !important;
}

.rs-picker-daterange-menu .rs-picker-daterange-header,
.rs-picker-daterange-menu .rs-picker-daterange-calendar-group,
.rs-picker-daterange-menu .rs-picker-toolbar,
.rs-picker-daterange-menu .rs-calendar-month-dropdown-row-wrapper,
.rs-picker-daterange-menu .rs-calendar-time-dropdown {
  background: #293368;
}
.rs-picker-daterange-menu .rs-calendar-header-month-toolbar button,
.rs-picker-daterange-menu .rs-calendar-header-time-toolbar button,
.rs-picker-daterange-menu .rs-stack-item button,
.rs-picker-daterange-menu .rs-calendar-time-dropdown-cell {
  color: #fff;
}

.rs-picker-daterange-menu .rs-calendar-time-dropdown-cell-active {
  background: #8476ef;
  color: #fff;
}

.rs-picker-daterange-menu .rs-calendar-header-title:hover,
.rs-calendar-header-title.rs-calendar-header-title-time:hover,
.rs-picker-daterange-menu .rs-calendar-header-meridian:hover,
.rs-picker-daterange-menu .rs-btn-icon:hover {
  background: #8476ef;
  color: #fff;
}

.comment {
  display: grid;
  white-space: pre-wrap;
}

div.comment p {
  display: inherit;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.highcharts-scrolling::-webkit-scrollbar {
  display: block;
  width: 60px;
  height: 15px;
  cursor: pointer;
}

.highcharts-scrollbar {
  margin-top: 30;
}

.highcharts-scrollbar-button {
  display: none;
}
.highcharts-scrollbar-arrow {
  display: none;
}
.highcharts-scrollbar-track {
  display: none;
}

.highcharts-scrollbar-rifles {
  display: none;
}
.css-r91iyi-MuiTablePagination-displayedRows {
  margin-top: 2.5px;
}
.css-11m2jde-MuiInputBase-root-MuiTablePagination-select {
  margin-top: 3px;
}
.css-ttjyrr-MuiToolbar-root-MuiTablePagination-toolbar .MuiIconButton-root {
  margin-top: 2px;
}

.e-schedule .e-outer-table > tbody > tr:first-child td {
  position: sticky;
  top: 0px;
  z-index: 100;
}
.rs-picker-daterange-menu, .rs-picker-menu{
  z-index: 10000000000; /* added a large random number, you can choose it more carefully based on your use-case.*/
}